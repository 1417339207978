import { navigate } from "gatsby";

const isBrowser = typeof window !== `undefined`

const getUser = () => isBrowser && window.localStorage.googleUser
  ? JSON.parse(window.localStorage.googleUser)
  : {}

const setUser = user => { if (isBrowser) window.localStorage.googleUser = JSON.stringify(user) }

export const handleLogin = (user) => {
  //console.warn(user);

  //make sure user is a WEAVE google account
  if (!isBrowser || !user.profileObj.email.includes('@getweave.com')) return false;

  if (user) {
    setUser(user);
    navigate(`/weave-admin/`)
  }

  return false;
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()

  return !!user.profileObj && !!user.profileObj.email
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return

  console.log(`Logging out.`)
  setUser({})
  callback()
}
