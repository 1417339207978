import React from 'react'
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../utils/auth"
import GoogleLogin from 'react-google-login'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'


class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    handleLogin(this.state)
  }

  responseGoogle(response) {
    console.log(response);
  }

  handleSuccess(response) {
    handleLogin(response)
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/weave-admin/`)
    }

    return (
      <Layout>
        <Helmet>
          <title>Login</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <section className="section thick">
          <div className="container skinny Center">Are you in the right place?</div>
          <div className="container skinny Center">
            <GoogleLogin
              clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}
              buttonText="Weave Employee Sign In"
              onSuccess={this.handleSuccess}
              onFailure={this.responseGoogle}
              className="form__button"
            />
          </div>
        </section>
      </Layout>
    )
  }
}

export default Login
